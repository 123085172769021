import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import ReactGA from 'react-ga'
import algoliasearch from 'algoliasearch/lite'

import {
  InstantSearch,
  SearchBox,
  Stats,
  Configure,
  Hits,
} from 'react-instantsearch-dom'

import Hit from './Hit'

import { Search as SearchStyled, Title } from './styles'

const trackSearch = term => {
  if (term.query.length) {
    ReactGA.event({
      category: 'Search',
      action: 'search',
      label: `Search - ${term.query}`,
    })
  }
}

const urlToSearchState = ({ search }) => qs.parse(search.slice(1))

function debounceEventSearch(setSearchState, fn, wait = 1000, time) {
  return function () {
    setSearchState(arguments[0])

    clearTimeout(time)

    time = setTimeout(() => {
      trackSearch.apply(this, arguments)
    }, wait)
  }
}

const Search = ({ algolia, callback, props }) => {
  const { location } = props
  const [searchState, setSearchState] = useState(urlToSearchState(location))

  const CBSetState = useCallback(newState => {
    setSearchState(newState)
  }, [])

  const searchClient =
    algolia && algoliasearch(algolia.appId, algolia.searchOnlyApiKey)

  const memoDebounce = useCallback(
    debounceEventSearch(setSearchState, trackSearch),
    []
  )

  return (
    <SearchStyled>
      {algolia && algolia.appId ? (
        <div>
          <InstantSearch
            searchClient={searchClient}
            indexName={algolia.indexName}
            searchState={searchState}
            onSearchStateChange={memoDebounce}
          >
            <Configure hitsPerPage={100} distinct />
            <SearchBox translations={{ placeholder: 'Pesquisar no blog...' }} />
            {searchState && searchState.query ? (
              <>
                <Stats
                  translations={{
                    stats(nbHits) {
                      return `${nbHits} resultados encontrados`
                    },
                  }}
                />
                <Hits hitComponent={Hit} />
              </>
            ) : (
              <nav>{callback}</nav>
            )}
          </InstantSearch>
        </div>
      ) : (
        <nav>{callback}</nav>
      )}
    </SearchStyled>
  )
}

Search.propTypes = {
  algolia: PropTypes.object.isRequired,
  callback: PropTypes.node,
}

export default Search
