import React from 'react'
import PropTypes from 'prop-types'

import { getActiveTheme } from 'utils/theme'

import { AniLinkButton as AniLinkButtonStyled } from './styles'

const AniLinkButton = ({ children, to }) => {
  return (
    <AniLinkButtonStyled
      to={to}
      cover
      direction='down'
      duration={1}
      bg={getActiveTheme()}
    >
      {children}
    </AniLinkButtonStyled>
  )
}

AniLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.node.isRequired,
}

export default AniLinkButton
