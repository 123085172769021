import styled from 'styled-components'

import { Border, Space } from 'utils/theme'

export const Pagination = styled.nav`
  align-items: center;
  border-top: ${Border.box};
  color: var(--secondaryColor);
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  margin-top: ${Space.lg};
  padding-bottom: ${Space.sm};
  padding-top: ${Space.sm};

  span {
    width: 100%;
    text-align: right;
  }
`
