import styled from 'styled-components'

import { Space, Border, Text2 } from 'utils/theme'

export const Search = styled.section`
  display: block;

  .ais {
    &-InstantSearch__root {
      position: relative;
    }

    &-SearchBox {
      margin-bottom: ${Space.md};
      position: relative;

      &-input {
        -webkit-appearance: none;
        background-color: var(--bg);
        border: ${Border.box};
        color: var(--primaryColor);
        display: block;
        font-size: 1.8rem;
        height: ${Space.lg};
        line-height: ${Space.lg};
        padding-left: 45px;
        padding-right: ${Space.xxlg};
        width: 100%;
      }

      &-reset,
      &-submit {
        align-items: center;
        border-left: ${Border.box};
        color: var(--secondaryColor);
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        top: 0;
        transition: color var(--transition);
        width: ${Space.lg};
        will-change: color;
      }

      &-submit {
        left: 0;
      }

      &-submit {
        &:hover,
        &:focus {
          color: var(--highlightColor);
        }
      }

      &-reset {
        right: 0;
        border: none;

        &[hidden] {
          display: none;
        }

        &:hover,
        &:focus {
          color: var(--highlightColor);
        }
      }

      &-submitIcon {
        height: ${Space.sm};
        width: ${Space.sm};
      }
    }

    &-Stats {
      ${Text2}
      color: var(--secondaryColor);
      margin-bottom: ${Space.xs};
    }

    &-Hits {
      &-item {
        &:not(:last-child) {
          margin-bottom: ${Space.default};
        }
      }
    }
  }
`

export const Title = styled.h1`
  ${Text2}
  align-items: center;
  color: var(--secondaryColor);
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${Space.sm};
  padding-top: ${Space.sm};
`
