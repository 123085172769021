import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import { getActiveTheme } from 'utils/theme'
import Tags from 'components/Tags'

import {
  PostItem as PostItemStyled,
  Subtitle,
  Title,
  TitleOnPostsPage,
  BoxHandler,
} from './styles'

const trackClick = ({ item, label }) => {
  ReactGA.event({
    category: 'Blog',
    action: 'click',
    label: `${label || 'Blog List'} - Go to ${item}`,
  })
}

const PostItem = ({
  slug,
  date,
  timeToRead,
  title,
  tags,
  description,
  postsPage = false,
}) => {
  return (
    <PostItemStyled
      to={`/${slug}`}
      cover
      direction='down'
      duration={1}
      title={title}
      bg={getActiveTheme()}
      onClick={() => trackClick(title)}
    >
      <BoxHandler>
        {date && (
          <time>
            {date}
            {timeToRead && <span> · Leitura de {timeToRead} min</span>}
          </time>
        )}
        {postsPage ? (
          <TitleOnPostsPage>{title}</TitleOnPostsPage>
        ) : (
          <Title>{title}</Title>
        )}
        {description && <Subtitle>{description}</Subtitle>}
        {tags && <Tags tags={tags} />}
      </BoxHandler>
    </PostItemStyled>
  )
}

PostItem.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  timeToRead: PropTypes.number,
  tags: PropTypes.array,
  description: PropTypes.string,
  postsPage: PropTypes.bool,
}

export default PostItem
