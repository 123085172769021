import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import media from 'styled-media-query'

import {
  Space,
  Heading4,
  Heading1,
  Subtitle2,
  Border,
  BorderRadius,
  BoxShadow,
} from 'utils/theme'

export const PostItem = styled(AniLink)`
  color: var(--primaryColor);
  display: block;

  &:not(:last-child) {
    margin-bottom: ${Space.default};
  }
`

export const TitleOnPostsPage = styled.h2`
  ${props => (props.isMini ? `${Heading4}` : `${Heading1}`)}

  &:not(:first-child) {
    margin-top: ${props => (props.isMini ? `${Space.xs}` : `${Space.sm}`)};
  }

  &:not(:last-child) {
    margin-bottom: ${Space.xs};
  }
`

export const Title = styled.h1`
  ${props => (props.isMini ? `${Heading4}` : `${Heading1}`)}

  &:not(:first-child) {
    margin-top: ${props => (props.isMini ? `${Space.xs}` : `${Space.sm}`)};
  }

  &:not(:last-child) {
    margin-bottom: ${Space.xs};
  }
`

export const Subtitle = styled.h2`
  ${Subtitle2}

  &:not(:last-child) {
    margin-bottom: ${Space.default};
  }
`

export const BoxHandler = styled.article`
  border: ${Border.box};
  border-radius: ${BorderRadius.box};
  box-shadow: ${BoxShadow.box};
  color: var(--primaryColor);
  display: block;
  height: 100%;
  min-height: 15rem;
  padding: ${Space.sm};
  position: relative;
  will-change: box-shadow;
  overflow: hidden;

  ${media.greaterThan('medium')`
    padding: ${Space.default};
  `}

  &:after,
  &:before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: box-shadow var(--transition) ease-in-out;
  }

  &:hover {
    &:before {
      box-shadow: inset 5px 5px 50px -5px rgba(255, 255, 255, .05);
    }

    &:after {
      box-shadow: inset -5px -5px 50px -5px  rgba(255, 255, 255, .05);
    }
  }
`
