import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { Link } from 'utils/theme'

export const AniLinkButton = styled(AniLink)`
  ${Link}
  display: inline-block;
  font-size: 1.6rem;
`
