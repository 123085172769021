import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { getActiveTheme } from 'utils/theme'

import { TagHolder, TagIcon, TagItem, Tags as TagsStyled } from './styles'

const trackClick = item => {
  ReactGA.event({
    category: 'Tag',
    action: 'click',
    label: `Tag - ${item}`,
  })
}

const Tags = ({ tags, isLink }) => {
  return (
    <TagsStyled>
      <TagIcon />
      {tags.map((tag, i) => (
        <TagHolder key={i}>
          {isLink ? (
            <AniLink
              to={`blog?query=` + tag}
              cover
              direction='down'
              duration={1}
              onClick={() => trackClick(tag)}
              bg={getActiveTheme()}
            >
              <TagItem>{tag}</TagItem>
            </AniLink>
          ) : (
            <TagItem>{tag}</TagItem>
          )}
        </TagHolder>
      ))}
    </TagsStyled>
  )
}

Tags.propTypes = {
  tags: PropTypes.node.isRequired,
  isLink: PropTypes.bool,
}

export default Tags
