import styled from 'styled-components'
import { Tag } from '@styled-icons/evil'

import { Space } from 'utils/theme'

export const Tags = styled.div`
  align-items: center;
  color: var(--primaryColor);
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: ${Space.xs};
  }
`

export const TagHolder = styled.span`
  align-items: center;
  display: inline-flex;

  > a {
    align-items: center;
    color: var(--primaryColor);
    display: inline-flex;
    transition: color var(--transition);
    will-change: color;

    &:not(:last-child) {
      margin-right: ${Space.xxs};
    }

    &:hover,
    &:focus {
      color: var(--highlightColor);
    }
  }

  &:not(:last-child) {
    margin-right: ${Space.xxs};

    &:after {
      content: ', ';
      height: 3rem;
    }
  }
`

export const TagItem = styled.span`
  align-items: center;
  display: inline-flex;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  text-transform: lowercase;
  vertical-align: top;
`

export const TagIcon = styled(Tag)`
  display: block;
  margin-right: ${Space.xs};
  width: ${Space.sm};
`
