import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import AniLinkButton from 'components/AniLinkButton'
import { Pagination as PaginationStyled } from './styles'

const trackClick = item => {
  ReactGA.event({
    category: 'Pagination',
    action: 'click',
    label: `Pagination - Go to page ${item}`,
  })
}

const Pagination = ({
  isFirst,
  isLast,
  nextPage,
  prevPage,
  currentPage,
  numPages,
}) => (
  <PaginationStyled>
    {!isFirst && (
      <AniLinkButton
        rel='prev'
        to={prevPage}
        onClick={() => trackClick(`previous page ${prevPage}`)}
      >
        ← Anterior
      </AniLinkButton>
    )}
    <span>
      {currentPage} de {numPages}
    </span>
    {!isLast && (
      <AniLinkButton
        rel='next'
        to={nextPage}
        onClick={() => trackClick(`next page ${nextPage}`)}
      >
        Próxima →
      </AniLinkButton>
    )}
  </PaginationStyled>
)

Pagination.propTypes = {
  numPages: PropTypes.number.isRequired,
  prevPage: PropTypes.string.isRequired,
  nextPage: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
}

export default Pagination
